import Network from '@/services/network'
import {ref} from 'vue'


export default function handleStock(){
    const network = new Network;

    const fetchStockTransferNumber = query => {
        return network.api('get', `/inventory/stock/transfer/get-number` + query);
    }

    const fetchStockTransfers = query => {
        return network.api('get', `/inventory/stock/transfer` + query);
    }

    const fetchStockTransfer = (query, transferId) => {
        return network.api('get', `/inventory/stock/transfer/${transferId}`+query)
    }

    const fetchProductStock = (query) => {
        return network.api('get', `/inventory/stock/transfer/product-stock`+query)
    }

    const storeStockTransfer = (query, data) => {
        return network.api('post', '/inventory/stock/transfer'+query, data)
    }

    const updateStockTransfer = (transferId, query, data) => {
        return network.api('put', `/inventory/stock/transfer/${transferId}`+query, data)
    }

    const fetchStockAdjustmentNumber = query => {
        return network.api('get', `/inventory/stock/adjustment/get-number` + query);
    }

    const fetchStockAdjustmentList = query => {
        return network.api('get', `/inventory/stock/adjustment` + query);
    }

    const storeStockAdjustment = (query, data) => {
        return network.api('post', `/inventory/stock/adjustment` + query, data);
    }

    const fetchStockAdjustment = (query, adjustmentId) => {
        return network.api('get', `/inventory/stock/adjustment/${adjustmentId}` + query);
    }

    const updateStockAdjustment = (adjustmentId, query, data) => {
        return network.api('patch', `/inventory/stock/adjustment/${adjustmentId}` + query, data);
    }

    return {
        fetchStockTransfers,
        fetchStockTransferNumber,
        storeStockTransfer,
        fetchStockTransfer,
        fetchProductStock,
        updateStockTransfer,
        fetchStockAdjustmentNumber,
        storeStockAdjustment,
        fetchStockAdjustmentList,
        fetchStockAdjustment,
        updateStockAdjustment,

    }
}
